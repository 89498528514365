import About from "../components/about/About";
import AdvisorsBackers from "./AdvisorsBackers";
import Blog from "./Blog";
import BlogDetails from "./BlogDetails";
import Contact from "./Contact";
import HelpCenter from "./HelpCenter";
import Home01 from "./Home01";
import Home02 from "./Home02";
import Home03 from "./Home03";
import Nfts from "./Nfts";
import ParticipantAssets from "./ParticipantAssets";
import Partners from "./Partners";
import RoadMap from "./RoadMap";
import Main from './Main';
import Team2 from "../components/team/Team2";
import dataTeam from "../assets/fake-data/dataTeam";
import VisionsMission from "./VisionsMission";
import Indoor from "../components/products/indoor/Indoor";
import Outdoor from "../components/products/outdoor/outdoor";
import Abs from "../components/products/Abs/Abs";
import Flexiblemodule from "../components/products/fm/fm";
import Im from "../components/products/IM/IM";
import Finepitch from "../components/products/fp/fp";
import Gallery from "../components/Gallery/Gallery";
import Branches from "../components/Branches/Branches";
import ProductDetails from "../components/project/productDetails"
import PincodeForm from "../components/branch/pincode_form";
import TermsOfService from "../components/TOS&PP/TOS";
import PrivacyPolicy from "../components/TOS&PP/PP";
// import Bg from "../components/animations/bg";





const routes = [
  // {path: '' ,component: <Bg />},
  { path: '/home-v1', component: <Home01 />},
  { path: '/home-v2', component: <Home02 />},
  { path: '/home-v3', component: <Home03 />},
  { path: '/product/:id', component: <ProductDetails />},
  { path: '/', component: <Main />},
  { path: '/Collection', component: <Nfts />},
  { path: '/Checkout', component: <PincodeForm />},
  { path: '/Indoor', component: <Indoor />},
  { path: '/outdoor', component: <Outdoor />},
  { path: '/Abs', component: <Abs />},
  { path: '/Fm', component: <Flexiblemodule />},
  { path: '/IM', component: <Im />},
  { path: '/gallery', component: <Gallery />},
  { path: '/branches', component: <Branches />},
  { path: '/fp', component: <Finepitch />},
  { path: '/blog', component: <Blog />},
  { path: '/blog-details', component: <BlogDetails />},
  { path: '/visions-mission', component: <VisionsMission />},
  { path: '/help-center', component: <HelpCenter />},
  { path: '/participants-assets', component: <ParticipantAssets />},
  { path: '/advisors-backers', component: <AdvisorsBackers />},
  { path: '/partners', component: <Partners />},
  { path: '/about', component: <About />},
  { path: '/road-map', component: <RoadMap />},
  { path: '/clients', component: <Team2 data={dataTeam} />},
  { path: '/contact', component: <Contact />},
  { path: '/contact', component: <ParticipantAssets />},
  { path: '/Terms', component: <TermsOfService />},
  { path: '/Privacy-Policy', component: <PrivacyPolicy />},



]

export default routes;