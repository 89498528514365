const menus = [
  {
    id: 1,
    name: 'Home',
    links: '/',
  },
  {
    id: 2,
    name: 'About',
    links: '/about',
  },
  {
    id: 3,
    name: 'Products',
    links: '',
    namesub: [
      {
        id: 1,
        sub: 'Indoor',
        links: '/Indoor',
      },
      {
        id: 2,
        sub: 'Outdoor',
       links:'/outdoor'
      },
      {
        id: 3,
        sub: 'FinePitch',
       links:'/fp'
      },
      {
        id: 4,
        sub: 'Flexible Module',
       links:'/Fm'
      },
      {
        id: 5,
        sub: 'Aluminum Bottom Shell',
       links:'/Abs'
      },
      {
        id: 6,
        sub: 'Indoor Monochrome',
       links:'/IM'
      },
    ],
  },
  // {
  //   id: 4,
  //   name: 'Gallery',
  //   links: '/gallery',
  // },
  // {
  //   id: 5,
  //   name: 'Clients',
  //   links: '/clients',
  // },
  {
    id: 6,
    name: 'Contact',
    links: '/contact',
  },
  {
    id: 7,
    name: 'Terms',
    links: '/Terms',
  },
  {
    id: 8,
    name: 'Privacy',
    links: '/Privacy-Policy',
  },
];

export default menus;
