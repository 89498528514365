import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="bg-gray-100 py-12 px-6 sm:px-12 lg:px-24 text-gray-800">
      <div className="max-w-7xl mx-auto bg-white shadow-lg rounded-lg p-8">
        <h1 className="text-3xl font-bold mb-6 text-center text-blue-600">Privacy Policy</h1>
        <p className="mb-6">
          At Rajdhani Sign Store, we are committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website. Please read this privacy policy carefully.  If you do not agree with the terms of this privacy policy, please do not access the site.
        </p>

        <h2 className="text-2xl font-semibold mb-4 text-blue-500">1. Information We Collect</h2>
        <p className="mb-6">
          We collect personal information that you voluntarily provide to us when you express an interest in obtaining information about us or our products and services, when you participate in activities on the Website, or otherwise when you contact us. The personal information that we collect depends on the context of your interactions with us and the Website, the choices you make, and the products and features you use. The personal information we collect may include names, phone numbers, email addresses, mailing addresses, and billing information.
        </p>

        <h2 className="text-2xl font-semibold mb-4 text-blue-500">2. How We Use Your Information</h2>
        <p className="mb-6">
          We process your information for purposes based on legitimate business interests, the fulfillment of our contract with you, compliance with our legal obligations, and/or your consent. We use personal information collected via our Website for a variety of business purposes described below. We process your personal information for these purposes in reliance on our legitimate business interests, in order to enter into or perform a contract with you, with your consent, and/or for compliance with our legal obligations.
        </p>

        <h2 className="text-2xl font-semibold mb-4 text-blue-500">3. Information Sharing</h2>
        <p className="mb-6">
          We may share information we have collected about you in certain situations. Your information may be disclosed as follows: to our subsidiaries and affiliates, to contractors, service providers, and other third parties we use to support our business, to fulfill the purpose for which you provide it, for any other purpose disclosed by us when you provide the information, with your consent, to comply with our legal obligations, to enforce our agreements, policies and terms of use, or if we believe disclosure is necessary or appropriate to protect the rights, property, or safety of Rajdhani Sign Store, our customers, or others.
        </p>

        <h2 className="text-2xl font-semibold mb-4 text-blue-500">4. Data Retention</h2>
        <p className="mb-6">
          We retain your personal information only for as long as necessary to provide you with our services and as described in our Privacy Policy. However, we may also be required to retain this information to comply with our legal and regulatory obligations, to resolve disputes, and to enforce our agreements. We generally keep your data for 6 years.
        </p>

        <h2 className="text-2xl font-semibold mb-4 text-blue-500">5. Transfer of Data</h2>
        <p className="mb-6">
          Your information, including Personal Data, may be transferred to — and maintained on — computers located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from your jurisdiction. Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.
        </p>

        <h2 className="text-2xl font-semibold mb-4 text-blue-500">6. Your Rights</h2>
        <p className="mb-6">
          We aim to take reasonable steps to allow you to correct, amend, delete, or limit the use of your Personal Data. If you wish to be informed what Personal Data we hold about you and if you want it to be removed from our systems, please contact us. In certain circumstances, you have the right: to access and receive a copy of the Personal Data we hold about you, to rectify any Personal Data held about you that is inaccurate, to request the deletion of Personal Data held about you. Please note that we may ask you to verify your identity before responding to such requests.
        </p>

        <h2 className="text-2xl font-semibold mb-4 text-blue-500">7. Security of Data</h2>
        <p className="mb-6">
          The security of your data is important to us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.
        </p>

        <h2 className="text-2xl font-semibold mb-4 text-blue-500">8. Changes to This Privacy Policy</h2>
        <p className="mb-6">
          We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.
        </p>

        <h2 className="text-2xl font-semibold mb-4 text-blue-500">9. Contact Us</h2>
        <p>
          If you have any questions about this Privacy Policy, please contact us at:
        </p>
        <ul className="list-disc pl-6 mb-6">
          <li>Email: <a href="mailto:rajdhanisignstorecard@gmail.com" className="text-blue-600">rajdhanisignstorecard@gmail.com</a></li>
          <li>Phone: +91 9845025998</li>
        </ul>
      </div>
    </div>
  );
};

export default PrivacyPolicy;