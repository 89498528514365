import React from 'react';

const TermsOfService = () => {
  return (
    <div className="bg-gray-100 py-12 px-6 sm:px-12 lg:px-24 text-gray-800">
      <div className="max-w-7xl mx-auto bg-white shadow-lg rounded-lg p-8">
        <h1 className="text-3xl font-bold mb-6 text-center text-blue-600">Terms of Service</h1>
        <p className="mb-6">
          Welcome to Rajdhani Sign Store! By accessing our website, you agree to comply with the following terms and conditions. Please read them carefully before using our services.
        </p>

        <h2 className="text-2xl font-semibold mb-4 text-blue-500">1. Acceptance of Terms</h2>
        <p className="mb-6">
          By accessing and using our website, you accept these Terms of Service in full. If you disagree with any part of the terms, you must not use our website.
        </p>

        <h2 className="text-2xl font-semibold mb-4 text-blue-500">2. Use of Our Services</h2>
        <p className="mb-6">
          You agree to use our website only for lawful purposes and in a way that does not infringe the rights of others, or restrict their use and enjoyment of the website. Prohibited behaviors include harassing, causing distress, or inconvenience to any person, and transmitting obscene or offensive content.
        </p>

        <h2 className="text-2xl font-semibold mb-4 text-blue-500">3. Intellectual Property Rights</h2>
        <p className="mb-6">
          All content, trademarks, logos, images, and other intellectual property displayed on the website are the property of Rajdhani Sign Store and are protected by applicable laws. You may not reproduce, distribute, or create derivative works without our express written consent.
        </p>

        <h2 className="text-2xl font-semibold mb-4 text-blue-500">4. Product Information</h2>
        <p className="mb-6">
          We strive to provide accurate product descriptions and pricing, but errors may occur. We reserve the right to correct any errors, inaccuracies, or omissions and to change or update information at any time without prior notice. All products are subject to availability.
        </p>

        <h2 className="text-2xl font-semibold mb-4 text-blue-500">5. Limitation of Liability</h2>
        <p className="mb-6">
          Rajdhani Sign Store will not be held liable for any damages, including without limitation, indirect or consequential damages, arising from your use of the website or your inability to use it. This includes but is not limited to damages caused by viruses or other harmful components.
        </p>

        <h2 className="text-2xl font-semibold mb-4 text-blue-500">6. Governing Law</h2>
        <p className="mb-6">
          These terms are governed by and construed in accordance with the laws of India. Any disputes relating to these terms will be subject to the exclusive jurisdiction of the courts of Bangalore, Karnataka.
        </p>

        <h2 className="text-2xl font-semibold mb-4 text-blue-500">7. Modifications to Terms</h2>
        <p className="mb-6">
          We reserve the right to modify these Terms of Service at any time, and such modifications will be effective immediately upon posting on the website. It is your responsibility to review these terms regularly to ensure you are aware of any changes.
        </p>

        <h2 className="text-2xl font-semibold mb-4 text-blue-500">8. Contact Us</h2>
        <p>
          If you have any questions about these Terms of Service, please contact us at:
        </p>
        <ul className="list-disc pl-6 mb-6">
          <li>Email: <a href="mailto:rajdhanisignstorecard@gmail.com" className="text-blue-600">rajdhanisignstorecard@gmail.com</a></li>
          <li>Phone: +91 9845025998</li>
        </ul>
      </div>
    </div>
  );
};

export default TermsOfService;