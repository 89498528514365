import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import { collection, getDocs } from "firebase/firestore";
import { db } from '../../config/config'
import Title from "../../assets/images/logo/TITLE.png";
import Banner1 from "./1.png";
import Banner2 from "./2.png";
import Banner3 from "./3.png";

const float = keyframes`
  0% { transform: translateY(0px); }
  50% { transform: translateY(-20px); }
  100% { transform: translateY(0px); }
`;

const glow = keyframes`
  0% { box-shadow: 0 0 15px rgba(128, 0, 128, 0.7), 0 0 30px rgba(128, 0, 128, 0.5); }
  50% { box-shadow: 0 0 40px rgba(128, 0, 128, 0.9), 0 0 60px rgba(128, 0, 128, 0.7); }
  100% { box-shadow: 0 0 15px rgba(128, 0, 128, 0.7), 0 0 30px rgba(128, 0, 128, 0.5); }
`;

const glowingBorder = keyframes`
  0% { box-shadow: 0 0 5px rgba(255, 255, 255, 0.7), 0 0 10px rgba(255, 255, 255, 0.5); }
  50% { box-shadow: 0 0 20px rgba(255, 255, 255, 0.9), 0 0 30px rgba(255, 255, 255, 0.7); }
  100% { box-shadow: 0 0 5px rgba(255, 255, 255, 0.7), 0 0 10px rgba(255, 255, 255, 0.5); }
`;

const ImageWrapper = styled.div`
  width: 100%;
  height: 400px; // Fixed height
  animation: ${float} 6s ease-in-out infinite, ${glow} 3s ease-in-out infinite;
  border-radius: 25px;
  overflow: hidden;
  transition: all 0.3s ease;
  position: relative;
  border: 5px solid rgba(255, 255, 255, 0.3);
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.3);
  cursor: pointer;

  &:hover {
    transform: scale(1.05);
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      to bottom,
      rgba(26, 0, 51, 0.5),
      rgba(26, 0, 51, 0)
    );
    z-index: 1;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain; // Changed from cover to contain
    transition: opacity 0.5s ease-in-out;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
  }

  img.active {
    opacity: 1;
  }
`;

const ImageTitle = styled.h3`
  margin-top: 15px;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 10px 20px;
  border-radius: 20px;
  font-size: 1.2rem;
  display: inline-block;
`;

const BannerSection = styled.section`
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
`;

const BackgroundSlideshow = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
`;

const BackgroundImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  opacity: 0;
  transition: opacity 1s ease-in-out;
  &.active {
    opacity: 1;
  }
`;

const TitleWrapper = styled.div`
  background-color: rgba(255, 255, 255, 0.9);
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  animation: ${glowingBorder} 3s ease-in-out infinite;
`;

const StyledTitle = styled.img`
  max-width: 100%;
  height: auto;
`;

const Subtitle = styled.p`
  font-size: clamp(1rem, 3vw, 1.8rem);
  color: #333;
  margin-top: 15px;
  font-weight: 500;
  text-align: center;
`;

const ProductImageWrapper = styled(ImageWrapper)`
  height: 400px; // Fixed height
`;

const ProductTitle = styled(ImageTitle)`
  font-size: 1rem;
  background-color: rgba(0, 0, 0, 0.8);
`;

function Banner() {
  const [currentBackground, setCurrentBackground] = useState(0);
  const [currentProduct, setCurrentProduct] = useState(0);
  const [randomProducts, setRandomProducts] = useState([]);
  const backgrounds = [Banner1, Banner2, Banner3];
  const navigate = useNavigate();

  useEffect(() => {
    const fetchRandomProducts = async () => {
      try {
        const productsSnapshot = await getDocs(collection(db, "products"));
        const products = productsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        const shuffled = products.sort(() => 0.5 - Math.random());
        setRandomProducts(shuffled.slice(0, 5));
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchRandomProducts();
  }, []);

  useEffect(() => {
    const backgroundInterval = setInterval(() => {
      setCurrentBackground((prev) => (prev + 1) % backgrounds.length);
    }, 5000);

    const productInterval = setInterval(() => {
      setCurrentProduct((prev) => (prev + 1) % randomProducts.length);
    }, 2500);

    return () => {
      clearInterval(backgroundInterval);
      clearInterval(productInterval);
    };
  }, [randomProducts]);

  const handleImageClick = () => {
    navigate('/collection');
  };

  return (
    <BannerSection className="banner">
      <BackgroundSlideshow>
        {backgrounds.map((bg, index) => (
          <BackgroundImage
            key={index}
            style={{ backgroundImage: `url(${bg})` }}
            className={index === currentBackground ? "active" : ""}
          />
        ))}
      </BackgroundSlideshow>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12">
            <div className="banner__left">
              <div className="block-text">
                <TitleWrapper>
                  <StyledTitle src={Title} alt="Rajdhani Sign Store" />
                  <Subtitle>
                    One of most leading sign store in India
                  </Subtitle>
                </TitleWrapper>
                <Link 
                  style={{
                    marginTop:'5vh'
                  }}
                  to="/collection" className="action-btn">
                  <span>Collection</span>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12">
            <div className="banner__right">
              <ProductImageWrapper onClick={handleImageClick}>
                {randomProducts.map((product, index) => (
                  <img
                    key={product.id}
                    src={product.mainImage}
                    alt={product.title}
                    className={index === currentProduct ? "active" : ""}
                  />
                ))}
              </ProductImageWrapper>
              <div style={{ textAlign: "center", marginTop: "15px" }}>
                <ProductTitle>
                  {randomProducts[currentProduct]?.title || "Featured Product"}
                </ProductTitle>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BannerSection>
  );
}

export default Banner;