import React, { useState, useEffect, useCallback, useRef } from "react";
import { Link } from "react-router-dom";
import menus from "../../pages/menu";
import logo1 from "../../assets/images/logo/logo.png";
import logo2 from "../../assets/images/logo/logo2.png";
import "./styles.scss";

const Header = () => {
  const [isFixed, setIsFixed] = useState(false);
  const [menuActive, setMenuActive] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);
  const [currentLogo, setCurrentLogo] = useState('logo1');
  const [opacity, setOpacity] = useState(1);
  const headerRef = useRef(null);
  const menuTimeoutRef = useRef(null);

  useEffect(() => {
    const header = headerRef.current;
    const observerOptions = {
      root: null,
      rootMargin: "-300px 0px 0px 0px", 
      threshold: [0, 1]
    };

    const observerCallback = (entries) => {
      const [entry] = entries;
      setIsFixed(!entry.isIntersecting);
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);
    
    if (header) {
      observer.observe(header);
    }

    return () => {
      if (header) {
        observer.unobserve(header);
      }
    };
  }, []);

  useEffect(() => {
    const animationSequence = [
      { logo: 'logo1', duration: 5000 },
      { logo: 'text', duration: 1700 },
      { logo: 'logo2', duration: 3000 },
    ];
    let currentIndex = 0;
    let timeout;

    const runAnimation = () => {
      const { logo, duration } = animationSequence[currentIndex];
      // Fade out
      setOpacity(0);
      
      // Change logo and fade in after a short delay
      setTimeout(() => {
        setCurrentLogo(logo);
        setOpacity(1);
      }, 200);

      // Move to next logo
      currentIndex = (currentIndex + 1) % animationSequence.length;
      
      // Schedule next animation
      timeout = setTimeout(runAnimation, duration);
    };

    runAnimation();
    return () => clearTimeout(timeout);
  }, []);

  const handleMenuActive = useCallback(() => {
    setMenuActive(prev => !prev);
    setActiveIndex(null);

    if (menuTimeoutRef.current) {
      clearTimeout(menuTimeoutRef.current);
    }

    if (!menuActive) {
      menuTimeoutRef.current = setTimeout(() => {
        setMenuActive(false);
      }, 2500);
    }
  }, [menuActive]);

  const handleDropdown = useCallback((index) => {
    setActiveIndex(prev => prev === index ? null : index);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest(".menu-item")) {
        setActiveIndex(null);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const resetInactivityTimer = useCallback(() => {
    if (menuTimeoutRef.current) {
      clearTimeout(menuTimeoutRef.current);
    }
    menuTimeoutRef.current = setTimeout(() => {
      setMenuActive(false);
    }, 2500);
  }, []);

  const renderMenuItems = useCallback((items, parentIndex = null) => {
    return items.map((data, idx) => {
      const currentIndex = parentIndex !== null ? `${parentIndex}-${idx}` : `${idx}`;

      return (
        <li
          key={currentIndex}
          onClick={() => {
            handleDropdown(currentIndex);
            resetInactivityTimer();
          }}
          className={`menu-item ${
            data.namesub ? "menu-item-has-children" : ""
          } ${activeIndex === currentIndex ? "active" : ""}`}
          style={{ color: 'black' }}
        >
          <Link 
            to={data.links} 
            className="menu-link"
            style={{ 
              color: 'black', 
              textDecoration: 'none',
              display: 'block',
              padding: '10px 15px'
            }}
          >
            {data.name || data.sub}
          </Link>
          {data.namesub && (
            <ul className="sub-menu" style={{ color: 'black' }}>
              {renderMenuItems(data.namesub, currentIndex)}
            </ul>
          )}
        </li>
      );
    });
  }, [activeIndex, handleDropdown, resetInactivityTimer]);

  const renderLogo = () => {
    const logoStyle = {
      width: "100%",
      height: "100%",
      objectFit: "contain",
      transition: "opacity 200ms ease-in-out",
      opacity: opacity
    };

    let content;
    if (currentLogo === 'logo1') {
      content = <img style={logoStyle} src={logo1} alt="Logo 1" />;
    } else if (currentLogo === 'logo2') {
      content = <img style={logoStyle} src={logo2} alt="Logo 2" />;
    } else {
      content = (
        <div 
          style={{
            ...logoStyle,
            display: "flex",
            alignItems: "center",
            color:"black",
            justifyContent: "center",
            fontSize: "24px",
            fontWeight: "bold",
          }}
        >
          OUR BRANDS
        </div>
      );
    }

    return (
      <div style={{ position: "relative", width: "100%", height: "100%" }}>
        {content}
      </div>
    );
  };

  return (
    <header
      ref={headerRef}
      id="header_main"
      className={`header ${isFixed ? "is-fixed" : ""}`}
      style={{ backgroundColor: 'white' }}
    >
      <div className="container big">
        <div className="row">
          <div className="col-12">
            <div className="header__body" style={{ display: 'flex', alignItems: 'center' }}>
              <div className="header__logo" style={{ width: '200px', height: '60px', overflow: 'hidden', position: 'relative' }}>
                <Link to="/" style={{ display: 'block', width: '100%', height: '100%' }}>
                  {renderLogo()}
                </Link>
              </div>

              <div className="header__right" style={{ flex: 1 }}>
                <nav
                  id="main-nav"
                  className={`main-nav ${menuActive ? "active" : ""}`}
                  style={{ backgroundColor: 'white' }}
                >
                  <ul id="menu-primary-menu" className="menu" style={{height:'2vh', color: 'black' }}>
                    {renderMenuItems(menus)}
                  </ul>
                </nav>
                <div
                  className={`mobile-button ${menuActive ? "active" : ""}`}
                  onClick={handleMenuActive}
                  style={{ color: 'black' }} 
                >
                  <span style={{ backgroundColor: 'black' }}></span>
                </div>
              </div>

              <div className="header__action">
                <Link 
                  to="/contact" 
                  className="action-btn"
                  style={{
                    color: 'white',
                    padding: '10px 20px',
                    borderRadius: '5px',
                    textDecoration: 'none',
                  }}
                >
                  <span>Contact</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;